// returns "Tue Aug 02 2022" format.
export const dateToString = date => {
  return date ? new Date(date).toDateString() : '-';
};

// returns "2022-08-02 10:00:00.000" format.
export const dateTimeFormat = date => {
  let dateJson = date.toJSON();
  dateJson = dateJson.replace('T', ' ');
  dateJson = dateJson.replace('Z', '');
  return dateJson;
};
// returns "2022-08-02" format.
export const dateWithoutTimeFormat = date => {
  let dateJson = date.toJSON();
  dateJson = dateJson.replace('T', ' ');
  dateJson = dateJson.replace('Z', '');
  dateJson = dateJson.slice(0, 10);
  return dateJson;
};
export const millisecBetween2Dates = (dateOne, dateTwo) => {
  return dateOne.getTime() - dateTwo.getTime();
};

export const checkIfFirstDateIsLessThanSecondDate = (firstDate, secondDate) => {
  return firstDate <= secondDate;
};

export const dateToStringByFormat = (format, date) => {
  const map = {
    YYYY: date.getFullYear(),
    YY: String(date.getFullYear()).slice(-2),
    MM: `0${date.getMonth() + 1}`.slice(-2),
    M: date.getMonth() + 1,
    DD: `0${date.getDate()}`.slice(-2),
    D: date.getDate(),
    HH: `0${date.getHours()}`.slice(-2),
    H: date.getHours(),
    mm: `0${date.getMinutes()}`.slice(-2),
    m: date.getMinutes(),
    SS: `0${date.getSeconds()}`.slice(-2),
    S: date.getSeconds(),
  };

  return format.replace(/YYYY|YY|MM|M|DD|D|HH|H|mm|m|SS|S/g, matched => map[matched]);
};
